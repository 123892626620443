import React from 'react';
// Copyright LEX AI GmbH. All Rights Reserved.

/**
 * SimpleCentered
 * https://tailwindui.com/components/marketing/sections/footers
 */
import Link from 'next/link';
import PropTypes from 'prop-types';

export default function SimpleCentered({ pages, socials, copyrightText }) {
  return (
    <footer>
      <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
          {pages.map((page) => (
            <div key={page.pageTitle} className="lg:col-span-2">
              <div className="px-5 py-2">
                <Link href={page.pageLink} passHref className="text-base text-indigo-600 hover:text-indigo-900">
                  {page.pageTitle}
                </Link>
              </div>
            </div>
          ))}
        </nav>
        <div className="mt-8 flex justify-center space-x-6">
          {socials.map((social) => (
            <div key={social.socialTitle} className="lg:col-span-2">
              <a href={social.socialLink} className="text-indigo-400 hover:text-indigo-500">
                <span className="sr-only">{social.socialTitle}</span>
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path fillRule="evenodd" d={social.socialIconID} clipRule="evenodd" />
                </svg>
              </a>
            </div>
          ))}
        </div>
        <p className="mt-8 text-center text-base text-indigo-400">&copy; {copyrightText}</p>
      </div>
    </footer>
  );
}

SimpleCentered.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      pageTitle: PropTypes.string.isRequired,
      pageLink: PropTypes.string.isRequired,
    })
  ).isRequired,
  socials: PropTypes.arrayOf(
    PropTypes.shape({
      socialTitle: PropTypes.string.isRequired,
      socialLink: PropTypes.string.isRequired,
      socialIconID: PropTypes.string.isRequired,
    })
  ).isRequired,
  copyrightText: PropTypes.string.isRequired,
};
