import React from 'react';
import Footer from '@components/Footer';
import Layout from '@components/Layout';
import { Routes } from '@lib/routes';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';

export default function NotFound() {
  const { locale } = useRouter();
  const homeUrl = `/${locale}${Routes.home}`;
  return (
    <Layout title="404: Page not found" description="Page not found">
      <div className="grid h-screen grid-cols-1 grid-rows-[1fr,auto,1fr] bg-white lg:grid-cols-[max(50%,36rem),1fr]">
        <header className="mx-auto w-full max-w-7xl px-6 pt-6 sm:pt-10 lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:px-8">
          <Link href={homeUrl}>
            <span className="sr-only">LEX AI GmbH</span>
            <Image src="/static/image/logo/logo.png" width={250} height={100} alt="" />
          </Link>
        </header>
        <main className="mx-auto w-full max-w-7xl px-6 py-24 sm:py-20 lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:px-8">
          <div className="max-w-lg">
            <p className="text-base font-semibold leading-8 text-indigo-600">404</p>
            <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Page not found</h1>
            <p className="mt-6 text-base leading-7 text-gray-600">
              Sorry, we couldn’t find the page you’re looking for.
            </p>
            <div className="mt-10">
              <Link href={homeUrl} className="text-sm font-semibold leading-7 text-indigo-600">
                <span>
                  <span aria-hidden="true">&larr;</span> Back to home
                </span>
              </Link>
            </div>
          </div>
        </main>
        <footer className="self-end lg:col-span-2 lg:col-start-1 lg:row-start-3">
          <Footer />
        </footer>
      </div>
    </Layout>
  );
}
